<template>
  <el-dialog
    :title="title"
    :visible="visible"
    width="670px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <slot />
    <div slot="footer">
      <el-button plain @click="$emit('close')">取消</el-button>
      <el-button type="primary" @click="$emit('confirm')">儲存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ResourceDialog',
  props: {
    title: { type: String },
    visible: { type: Boolean },
  },
  setup (props) {
    return { }
  },
})
</script>
<style lang="postcss" scoped>
</style>
