<template>
  <section class="section-block">
    <SectionTitle :title="title" @edit="$emit('edit')" />
    <el-form
      v-model="data"
      label-position="left"
      label-width="250px"
    >
      <slot />
    </el-form>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ResourceConfigSettings',
  props: {
    title: { type: String },
    formData: { type: Object },
  },
  setup (props) {
    const data = props.formData

    return { data }
  },
})
</script>
<style>
</style>
